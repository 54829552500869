const isPhone = (phone) => {
	var myreg = /^1[3-9]\d{9}$/;
	var res = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
	var result = res.test(phone)

	if (!myreg.test(phone) && !result) {
		return false
	} else {
		return true
	}
}
export default isPhone