// 身份证校验
const isCardNo = (card) => {
	let flag = false
	if (card.length === 15) {
		let reg =
			/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
		flag = reg.test(card)
	} else {
		var weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
		// 校验码
		var checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

		var code = card + ''
		var last = card[17] // 最后一位

		var seventeen = code.substring(0, 17)

		// ISO 7064:1983.MOD 11-2
		// 判断最后一位校验码是否正确
		var arr = seventeen.split('')
		var len = arr.length
		var num = 0
		for (var i = 0; i < len; i++) {
			num = num + arr[i] * weightFactor[i]
		}

		// 获取余数
		var resisue = num % 11
		var lastNo = checkCode[resisue]

		// 格式的正则
		// 正则思路
		var idcardPatter =
			/^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/

		// 判断格式是否正确
		var format = idcardPatter.test(card)

		// 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
		flag = !!(last === lastNo && format)
	}
	return flag
}
// export default getFormat
export default isCardNo