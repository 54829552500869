<template>
  <div class="apply">
    <div class="bg"></div>
    <div class="top-img flex between center">
      <img class="pointer" src="@/assets/sign-e.png" @click="toUrl()" />
      <div class="pointer">
        <span @click="toUrl()">首页</span> |
        <span @click="toGo()">返回</span>
      </div>
    </div>
    <div class="apply-nav flex">
      <div class="nav-item flex center nav-item-a between">
        <span>报名须知</span>
        <i class="el-icon-arrow-right"></i>
      </div>
      <!-- <div
        class="nav-item flex center between"
        :class="{ 'nav-item-a': status == 2 || status == 3 }"
      >
        <span>防疫要求</span>
        <i class="el-icon-arrow-right"></i>
      </div> -->
      <div
        class="nav-item flex center between"
        :class="{ 'nav-item-a': status == 2 }"
      >
        <span>资料登记</span>
        <!-- <i class="el-icon-arrow-right"></i> -->
      </div>
      <!-- <div
        class="nav-item flex center between"
        :class="{ 'nav-item-a': status == 1 || status == 2 }"
      >
        <span>报考信息</span>
        <i class="el-icon-arrow-right"></i>
      </div> -->
      <!-- <div class="nav-item flex center" :class="{ 'nav-item-a': status == 3 }">
        <span>缴费</span>
      </div> -->
    </div>
    <!-- 报名须知 -->
    <div v-show="status == 1">
      <div class="status1 info-type1">
        <div v-html="xuzhi.content"></div>
      </div>
      <div class="checkbox">
        <el-checkbox v-model="checked">我已阅读考试须知文件</el-checkbox>
      </div>
      <div class="sub-btn" v-if="checked" @click="status = 2">下一步</div>
      <div class="sub-btn2" v-else>下一步</div>
    </div>

    <!-- 防疫要求 -->
    <!-- <div class="status2 info-type1" v-show="status == 2">
      <div class="status2-main">
        <div class="status2-tips" v-html="jkmxz.content">
        
        </div>
        <div class="code-img flex between">
          <div class="code-img1">
            <img :src="jkmxzImg.path" />
          </div>
          <div class="upImg flex">
            <div><span>*</span>上传本人一展码：</div>
            <el-upload
              class="avatar-uploader"
              action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
              :show-file-list="false"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
            >
              <img
                v-if="health_code_image != ''"
                :src="health_code_image"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="upImg flex">
            <div><span>*</span>上传本人行程码：</div>
            <el-upload
              class="avatar-uploader"
              action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
              :show-file-list="false"
              :on-success="handleAvatarSuccess3"
              :before-upload="beforeAvatarUpload3"
            >
              <img
                v-if="travel_code_image != ''"
                :src="travel_code_image"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="sub-btn" @click="toStatus()">下一步</div>
    </div> -->
    <!-- 资料登记 -->
    <div class="info-type1" v-show="step == 1 && status == 2">
      <div class="type2-tit">岗位选择</div>
      <div class="input-items">
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>岗位选择</div>
          <el-select
          style="width:90%"
            v-model="info.position_id"
            placeholder="请选择"
            @change="$set(info, info.position_id, $event)"
          >
            <el-option
              v-for="item in positionList"
              :key="item.id"
              :label="item.name+'（岗位代码：'+item.code+'）'"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <div>{{info.position_id}}</div> -->
        </div>
        <!-- <div class="input-item flex">
          <div class="input-tit"><span>*</span>考试类型</div>
          <el-select v-model="info.exam_type" placeholder="请选择">
            <el-option
              v-for="item in examList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>
      <div class="type2-tit">我的照片</div>
      <div class="type2-mian flex between">
        <div class="main-left">
          <!-- <div
            @click="toggleShow"
            style="
              width: 295px;
              height: 413px;
              margin-bottom: 10px;
              margin: 0 auto;
              box-shadow: 0 3px 3px #e1d9d9;
              border: dashed 1px #707070;
            "
          >
            <img src="@/assets/up.png" v-if="imgDataUrl == ''" class="up" />
            <img
              v-else
              :src="imgDataUrl"
              style="display: block; width: 100%; height: 100%"
            />
          </div>
          <my-upload
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            :modelValue.sync="show"
            :width="295"
            :height="431"
            img-format="png"
            :size="size"
            langType="zh"
            :noRotate="false"
            field="file"
            :url="urlUpdate"
          ></my-upload> -->
          <el-upload
            class="avatar-uploader"
            action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imgDataUrl" :src="imgDataUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="main-right">
          <div>照片要求</div>
          <div style="line-height: 2" v-html="zpyq.content">
          
          </div>
        </div>
      </div>
      <div class="type2-tit">基本信息</div>
      <div class="input-items">
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>姓名</div>
          <el-input v-model="info.name" placeholder="请输入姓名"></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>性别</div>
          <div style="width: 70%">
            <el-radio v-model="info.sex" label="1">男</el-radio>
            <el-radio v-model="info.sex" label="2">女</el-radio>
          </div>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>证件类型</div>
          <el-input
            v-model="id_type"
            placeholder="请输入证件类型"
            readonly
          ></el-input>
          <!-- <el-select v-model="value1" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>证件编号</div>
          <el-input
            v-model="info.id_number"
            placeholder="请输入证件编号"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>出生日期</div>
          <el-date-picker
            v-model="info.birthday"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>民族</div>
          <el-select
            v-model="info.nation_id"
            placeholder="请选择"
            @change="$set(info, info.nation_id, $event)"
          >
            <el-option
              v-for="itemm in nationList"
              :key="itemm.id"
              :label="itemm.name"
              :value="itemm.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>婚姻状况</div>
          <el-select
            v-model="info.marital_status_id"
            placeholder="请选择"
            @change="$set(info, info.marital_status_id, $event)"
          >
            <el-option
              v-for="item1 in maritalList"
              :key="item1.id"
              :label="item1.name"
              :value="item1.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-item flex">
          <div class="input-tit">身高（cm）</div>
          <el-input v-model="info.height" placeholder="请输入身高"></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit">通讯地址</div>
          <el-input
            v-model="info.contact_address"
            placeholder="请输入通讯地址"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit">外语水平</div>
          <el-input
            v-model="info.foreign_language_level"
            placeholder="请输入外语水平"
          ></el-input>
        </div>

        <div class="input-item flex">
          <div class="input-tit"><span>*</span>政治面貌</div>
          <el-select
            v-model="info.politics_status_id"
            placeholder="请选择"
            @change="$set(info, info.politics_status_id, $event)"
          >
            <el-option
              v-for="item2 in politicsList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="input-item flex"
          v-if="info.politics_status_id == 60 || info.politics_status_id == 61"
        >
          <div class="input-tit">入党时间</div>
          <el-date-picker
            v-model="info.party_time"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>现住址</div>
          <el-input
            v-model="info.live_address"
            placeholder="请输入现住址"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>户籍属地</div>
          <el-input
            v-model="info.registered_residence"
            placeholder="请输入户籍属地"
          ></el-input>
          <!-- <el-cascader
            size="large"
            :options="optionsArea"
            v-model="info.registered_residence_code"
            @change="handleChange2"
            placeholder="请选择户籍属地"
          >
          </el-cascader> -->
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>籍贯</div>
          <el-input
            v-model="info.native_place"
            placeholder="请输入籍贯"
          ></el-input>
          <!-- <el-cascader
            size="large"
            :options="optionsArea"
            v-model="info.native_place_code"
            @change="handleChange"
            placeholder="请选择籍贯"
          >
          </el-cascader> -->
          <!-- <el-select v-model="value2" placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>联系电话</div>
          <el-input
            v-model="info.telphone"
            placeholder="请输入联系电话"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>邮箱</div>
          <el-input v-model="info.email" placeholder="请输入邮箱"></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>最高学历</div>
          <el-select
            v-model="info.education_id"
            placeholder="请选择"
            @change="$set(info, info.education_id, $event)"
          >
            <el-option
              v-for="item3 in educationList"
              :key="item3.id"
              :label="item3.name"
              :value="item3.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>最高学位</div>
          <el-select
            v-model="info.degree_id"
            placeholder="请选择"
            @change="$set(info, info.degree_id, $event)"
          >
            <el-option
              v-for="item in degreeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="input-item flex"
          v-if="
            info.degree_id == 71 || info.degree_id == 72 || info.degree_id == 73
          "
        >
          <div class="input-tit">学士证编号</div>
          <el-input
            v-model="info.degree_certificate_no"
            placeholder="请输入学士证编号"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit">自我评价</div>
          <el-input
            v-model="info.self_evaluation"
            :rows="2"
            type="textarea"
            placeholder="请输入自我评价"
          ></el-input>
        </div>
        <div class="input-items flex">
          <div class="sub-btn" @click="status = 1">上一步</div>
          <div class="sub-btn" @click="toStep()">下一步</div>
        </div>
      </div>
    </div>
    <div class="info-type1" v-show="step == 2 && status == 2">
      <div class="type2-tit" :data-id="info.education.length">教育背景</div>
      <div
        class="input-items input-items2"
        v-for="(education, index) in info.education"
        :key="index + 'index3'"
      >
        <div
          class="del pointer"
          @click="todel(1, index)"
          v-if="education.type == '1'"
        >
          <i class="el-icon-close"></i>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>就读时间</div>
          <div class="flex pickdata center">
            <el-date-picker
              v-model="education.start_time"
              type="month"
              placeholder="开始时间"
            >
            </el-date-picker>
            <span> 至 </span>
            <el-date-picker
              v-model="education.end_time"
              type="month"
              placeholder="结束时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>学校</div>
          <el-input
            v-model="education.school_name"
            placeholder="请输入学校"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>专业</div>
          <el-input
            v-model="education.major"
            placeholder="请输入专业"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>学历</div>
          <el-select
            v-model="education.education_id"
            placeholder="请选择"
            @change="$set(education, education.education_id, $event)"
          >
            <el-option
              v-for="item1 in educationList"
              :key="item1.id + 'info1'"
              :label="item1.name"
              :value="item1.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>学位</div>
          <el-select
            v-model="education.degree_id"
            placeholder="请选择"
            @change="$set(education, education.degree_id, $event)"
          >
            <el-option
              v-for="item2 in degreeList"
              :key="item2.id + 'info2'"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="input-item flex">
          <div class="input-tit"><span>*</span>是否全日制</div>
          <div style="width: 70%">
            <el-radio v-model="education.is_fulltime" label="1">是</el-radio>
            <el-radio v-model="education.is_fulltime" label="0">否</el-radio>
          </div>
        </div>
      </div>
      <div class="type2-tit2" @click="toAdd(1)">
        <i class="el-icon-circle-plus-outline"></i>添加教育背景
      </div>
      <div class="type2-tit">资格证书</div>
      <div
        class="input-items input-items2"
        v-for="(certificate, index2) in info.certificate"
        :key="index2"
      >
        <div class="del pointer" @click="todel(2, index2)">
          <i class="el-icon-close"></i>
        </div>
        <div class="input-item flex">
          <div class="input-tit">取得证书名称</div>
          <el-input
            v-model="certificate.name"
            placeholder="请输入取得证书名称"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit">获得时间</div>
          <el-date-picker
            v-model="certificate.get_time"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="input-item flex">
          <div class="input-tit">发证机构</div>
          <el-input
            v-model="certificate.organization_name"
            placeholder="请输入发证机构"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit">证书业务放向</div>
          <el-input
            v-model="certificate.serivce_direction"
            placeholder="请输入证书业务放向"
          ></el-input>
        </div>
      </div>
      <div class="type2-tit2" @click="toAdd(2)">
        <i class="el-icon-circle-plus-outline"></i>添加资格证书
      </div>
      <div class="type2-tit">社会经验</div>
      <div
        class="input-items input-items2"
        v-for="(experience, index) in info.experience"
        :key="index + 'index2'"
      >
        <div class="del pointer" @click="todel(3, index)">
          <i class="el-icon-close"></i>
        </div>
        <div class="input-item flex">
          <div class="input-tit">起止时间</div>
          <div class="flex pickdata center">
            <el-date-picker
              v-model="experience.start_time"
              type="month"
              placeholder="开始时间"
            >
            </el-date-picker>
            <span> 至 </span>
            <el-date-picker
              v-model="experience.end_time"
              type="month"
              placeholder="结束时间"
            >
            </el-date-picker>
          </div>
          <!-- <el-date-picker
            v-model="experience.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker> -->
        </div>
        <div class="input-item flex">
          <div class="input-tit">单位名称及部门</div>
          <el-input
            v-model="experience.name"
            placeholder="请输入单位名称及部门"
          ></el-input>
        </div>
        <div class="input-item flex">
          <div class="input-tit">担任职务或岗位</div>
          <el-input
            v-model="experience.position"
            placeholder="请输入担任职务或岗位"
          ></el-input>
        </div>
      </div>
      <div class="type2-tit2" @click="toAdd(3)">
        <i class="el-icon-circle-plus-outline"></i>添加社会经验
      </div>
      <div class="type2-tit flex">
        <img src="@/assets/upIcon.png" />
        上传附件
        <span>（可上传jpg、png、pdf、doc、docx、xls、xlsx）格式文件</span>
      </div>
      <div class="fujian flex between">
        <div class="fujian-item">
          <!-- 毕业证书 -->
          <el-upload
            class="upload-demo"
            action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
            :on-preview="handlePreview"
            :file-list="imgListUrl"
            multiple
            :on-success="handleAvatarSuccess4"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
          >
            <div class="flex upFujian between">
              <div>毕业证</div>
              <span class="flex center">
                <img src="@/assets/upIcon2.png" />
                <span>上传</span>
              </span>
            </div>
            <div
              v-if="imgListPath.length == 0"
              class="zan"
              style="text-align: left"
            >
              待上传
            </div>
          </el-upload>
        </div>
        <div class="fujian-item">
          <!-- 电子注册备案表文件 -->
          <el-upload
            class="upload-demo"
            action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
            :on-preview="handlePreview5"
            :file-list="imgListUrl5"
            multiple
            :on-success="handleAvatarSuccess5"
            :on-remove="handleRemove5"
            :before-remove="beforeRemove5"
          >
            <div class="flex upFujian between">
              <div>电子注册备案表文件</div>
              <span class="flex center">
                <img src="@/assets/upIcon2.png" />
                <span>上传</span>
              </span>
            </div>
            <div
              v-if="imgListPath5.length == 0"
              class="zan"
              style="text-align: left"
            >
              待上传
            </div>
          </el-upload>
        </div>
        <div class="fujian-item">
          <!-- 相关证书文件 -->
          <el-upload
            class="upload-demo"
            action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
            :on-preview="handlePreview6"
            :file-list="imgListUrl6"
            multiple
            :on-success="handleAvatarSuccess6"
            :on-remove="handleRemove6"
            :before-remove="beforeRemove6"
          >
            <div class="flex upFujian between">
              <div>相关证书文件</div>
              <span class="flex center">
                <img src="@/assets/upIcon2.png" />
                <span>上传</span>
              </span>
            </div>
            <div
              v-if="imgListPath6.length == 0"
              class="zan"
              style="text-align: left"
            >
              待上传
            </div>
          </el-upload>
        </div>
        <div class="fujian-item">
          <!-- 学位证文件 -->
          <el-upload
            class="upload-demo"
            action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
            :on-preview="handlePreview7"
            :file-list="imgListUrl7"
            multiple
            :on-success="handleAvatarSuccess7"
            :on-remove="handleRemove7"
            :before-remove="beforeRemove7"
          >
            <div class="flex upFujian between">
              <div>学位证文件</div>
              <span class="flex center">
                <img src="@/assets/upIcon2.png" />
                <span>上传</span>
              </span>
            </div>
            <div
              v-if="imgListPath7.length == 0"
              class="zan"
              style="text-align: left"
            >
              待上传
            </div>
          </el-upload>
        </div>
        <div class="fujian-item">
          <!-- 身份证文件 -->
          <el-upload
            class="upload-demo"
            action="https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"
            :on-preview="handlePreview8"
            :file-list="imgListUrl8"
            multiple
            :on-success="handleAvatarSuccess8"
            :on-remove="handleRemove8"
            :before-remove="beforeRemove8"
          >
            <div class="flex upFujian between">
              <div>身份证文件</div>
              <span class="flex center">
                <img src="@/assets/upIcon2.png" />
                <span>上传</span>
              </span>
            </div>
            <div
              v-if="imgListPath8.length == 0"
              class="zan"
              style="text-align: left"
            >
              待上传
            </div>
          </el-upload>
        </div>
      </div>
      <div class="input-items flex">
        <div class="sub-btn" @click="step = 1">上一步</div>
        <div class="sub-btn" @click="toSubmit" v-if="type == 1">提交</div>
        <div class="sub-btn" @click="toSubmit" v-if="type == 2">重新提交</div>
      </div>
    </div>
  </div>
</template>
<script>
import "babel-polyfill"; // es6 shim
// import myUpload from "vue-image-crop-upload";
import getFormat from "@/until/getTime.js";
import getMouth from "@/until/getMouth.js";
import isCardNo from "@/until/isCardNo.js";
import isPhone from "@/until/isPhone.js";
import isEmail from "@/until/isPhone.js";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
} from "element-china-area-data"; // 引入数据
import {
  userDetail,
  categoryLists,
  applySubmit,
  positionDetail,
  getExamPostion,
  commonConfig,
  applyDetail,
  applyEdit,
} from "@/api/apiData";
export default {
  data() {
    return {
      checked: false,
      health_code_image: "",
      travel_code_image: "",
      info: {
        foreign_language_level: "",
        height: "",
        contact_address: "",
        self_evaluation: "",
        position_id: "",
        health_code_image: "",
        travel_code_image: "",
        avatar: "",
        name: "",
        id_number: "",
        birthday: "",
        nation: "",
        telphone: "",
        sex: "1",
        marital_status_id: "",
        marital: "",
        politics_status_id: "",
        politics: "",
        registered_residence: "",
        registered_residence_code: "", //户籍编码
        live_address: "",
        native_place: "",
        native_place_code: "", //籍贯编码
        email: "",
        party_time: "",
        jdtime: "",
        education: [
          {
            time: "",
            school_name: "",
            major: "",
            is_fulltime: "",
            start_time: "",
            end_time: "",
            education_id: "",
            degree_id: "",
            type: "0",
          },
        ],
        certificate: [],
        experience: [],
        diploma_files: "", //毕业证文件
        electronic_record_files: "", //电子注册备案表文件
        certificate_files: "", //相关证书文件
        degree_files: "", //学位证文件
        idcard_files: "", //身份证文件
      },
      id_type: "身份证",
      status: 1,
      name: "",
      radio: "1",
      options1: [
        {
          value: "选项1",
          label: "身份证",
        },
        {
          value: "选项2",
          label: "护照",
        },
      ],
      value1: "", //证件类型
      options2: [
        {
          value: "选项1",
          label: "汉族",
        },
        {
          value: "选项2",
          label: "满族",
        },
      ],
      value2: "", //民族
      value3: "", //日期
      imgDataUrl: "",
      show: false,
      size: 2.1,
      imageUrl: "",
      urlUpdate: "",
      token: {
        token: "",
      },
      optionsArea: regionData,
      selectedOptions: [], // 选中绑定数据
      nationList: [],
      maritalList: [],
      politicsList: [],
      educationList: [],
      degreeList: [],
      examList: [],
      positionList: [],
      native_place: "",
      step: 1,
      stepUp: 1,
      xuzhi: "",
      zpyq: "",
      imgListUrl: [], //毕业证文件
      imgListPath: [], //毕业证文件
      imgListUrl5: [], //电子注册备案表文件
      imgListPath5: [], //电子注册备案表文件
      imgListUrl6: [], //相关证书文件
      imgListPath6: [], //相关证书文件
      imgListUrl7: [], //学位证文件
      imgListPath7: [], //学位证文件
      imgListUrl8: [], //身份证文件
      imgListPath8: [], //身份证文件
      type: 1,
      jkmxz: "",
      jkmxzImg: "",
    };
  },
  mounted() {
    console.log(regionData);
    this.$bus.emit("navS", 0);
    this.getInfo();
    this.getList("nation");
    this.getList("marital_status");
    this.getList("politics_status");
    this.getList("education");
    this.getList("degree");
    this.getPositionDetail();
    this.getPostion();
    let type = this.$route.query.type;
    this.type = type;
    if (type == 2) {
      this.getDetail();
    }
    // this.getType();
  },
  // components: {
  //   "my-upload": myUpload,
  // },
  created() {
    this.token.token = sessionStorage.getItem("token");
    this.urlUpdate =
      "https://gxrcexam.nccloudmedia.com/index.php/api/upload/image"; //上传图片地址
  },
  methods: {
    handleIsopenSelect() {
      console.log(222);
      this.$forceUpdate();
    },
    // 获取报考详情
    getDetail() {
      let id = this.$route.query.examId;
      applyDetail({ exam_apply_id: id }).then((res) => {
        // this.info = res.data;
        this.info.exam_apply_id = id;
        // 本人一展码\本人行程码
        let url1 =
          "/" + res.data.health_code_image.split("/").slice(3).join("/");
        let url2 =
          "/" + res.data.travel_code_image.split("/").slice(3).join("/");
        this.health_code_image = "https://gxrcexam.nccloudmedia.com" + url1;
        this.info.health_code_image = url1;
        this.travel_code_image = "https://gxrcexam.nccloudmedia.com" + url2;
        this.info.travel_code_image = url2;
        // 岗位
        this.info.position_id = res.data.position.id;
        // 头像
        let url = "/" + res.data.avatar.split("/").slice(3).join("/");
        this.imgDataUrl = "https://gxrcexam.nccloudmedia.com" + url;
        this.imgDataPath = url;
        this.avatar = "https://gxrcexam.nccloudmedia.com" + url;
        this.info.avatar = url;
        // 性别
        this.info.sex = res.data.sex.toString();
        this.info.id_number = res.data.id_number;
        this.info.birthday = res.data.birthday;
        this.info.nation_id = res.data.nation_id;
        this.info.marital_status_id = res.data.marital_status_id;
        this.info.politics_status_id = res.data.politics_status_id;
        this.info.party_time = res.data.party_time;
        this.info.live_address = res.data.live_address;
        this.info.registered_residence = res.data.registered_residence;
        if (res.data.registered_residence_code) {
          this.info.registered_residence_code =
            res.data.registered_residence_code;
        }
        this.info.native_place = res.data.native_place;
        if (res.data.native_place_code) {
          this.info.native_place_code = res.data.native_place_code;
        }
        if (res.data.foreign_language_level) {
          this.info.foreign_language_level = res.data.foreign_language_level;
          this.info.height = res.data.height;
          this.info.contact_address = res.data.contact_address;
          this.info.self_evaluation = res.data.self_evaluation;
        }
        this.info.telphone = res.data.telphone;
        this.info.email = res.data.email;
        this.info.education_id = res.data.education_id;
        this.info.degree_id = res.data.degree_id;
        // this.info.degree_certificate_no = res.data.degree_certificate_no;
        this.$set(
          this.info,
          "degree_certificate_no",
          res.data.degree_certificate_no
        );
        this.info.education = res.data.education_list;
        this.info.education.forEach((item) => {
          item.education_id = item.education.id;
          item.degree_id = item.degree.id;
          item.is_fulltime = item.is_fulltime.toString();
        });
        this.info.certificate = res.data.certificate_list;
        this.info.experience = res.data.experience_list;
        // 毕业证文件
        let imgList1 = res.data.diploma_files;
        let newList1 = [];
        let newListPath1 = [];
        imgList1.forEach((item) => {
          newList1.push({
            name: item.extparam.name,
            url: item.url,
          });
          newListPath1.push("/" + item.url.split("/").slice(3).join("/"));
        });
        this.imgListUrl = newList1;
        this.imgListPath = newListPath1;
        // 电子注册备案表文件
        let imgList2 = res.data.electronic_record_files;
        let newList2 = [];
        let newListPath2 = [];
        imgList2.forEach((item) => {
          newList2.push({
            name: item.extparam.name,
            url: item.url,
          });
          newListPath2.push("/" + item.url.split("/").slice(3).join("/"));
        });
        this.imgListUrl5 = newList2;
        this.imgListPath5 = newListPath2;
        // 相关证书文件
        let imgList3 = res.data.certificate_files;
        let newList3 = [];
        let newListPath3 = [];
        imgList3.forEach((item) => {
          newList3.push({
            name: item.extparam.name,
            url: item.url,
          });
          newListPath3.push("/" + item.url.split("/").slice(3).join("/"));
        });
        this.imgListUrl6 = newList3;
        this.imgListPath6 = newListPath3;
        // 学位证文件
        let imgList4 = res.data.degree_files;
        let newList4 = [];
        let newListPath4 = [];
        imgList4.forEach((item) => {
          newList4.push({
            name: item.extparam.name,
            url: item.url,
          });
          newListPath4.push("/" + item.url.split("/").slice(3).join("/"));
        });
        this.imgListUrl7 = newList4;
        this.imgListPath7 = newListPath4;
        // 身份证文件
        let imgList5 = res.data.idcard_files;
        let newList5 = [];
        let newListPath5 = [];
        imgList5.forEach((item) => {
          newList5.push({
            name: item.extparam.name,
            url: item.url,
          });
          newListPath5.push("/" + item.url.split("/").slice(3).join("/"));
        });
        this.imgListUrl8 = newList5;
        this.imgListPath8 = newListPath5;
      });
    },
    toStatus() {
      console.log(this.info);
      if (
        this.info.health_code_image == "" ||
        this.info.travel_code_image == ""
      ) {
        this.$message.error("请上传图片！");
      } else {
        this.status = 2;
      }
    },
    // 岗位选择
    getPostion() {
      getExamPostion({ exam_id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.positionList = res.data;
        }
      });
    },
    // 考试类型
    getType() {
      commonConfig({ name: "exam_type" }).then((res) => {
        if (res.code == 1) {
          this.examList = res.data;
        }
      });
    },
    // 报名须知
    getPositionDetail() {
      positionDetail({ posid: 5 }).then((res) => {
        if (res.code == 1) {
          this.xuzhi = res.data;
        }
      });
      positionDetail({ posid: 6 }).then((res) => {
        if (res.code == 1) {
          this.zpyq = res.data;
        }
      });
      positionDetail({ posid: 7 }).then((res) => {
        if (res.code == 1) {
          this.jkmxz = res.data;
        }
      });
      positionDetail({ posid: 8 }).then((res) => {
        if (res.code == 1) {
          this.jkmxzImg = res.data;
        }
      });
    },
    // 获取用户详情
    getInfo() {
      userDetail().then((res) => {
        // this.info.avatar = res.data.avatar;
        this.info.name = res.data.truename;
        this.info.id_number = res.data.id_number;
        this.info.birthday = res.data.birthday;
        if (res.data.nation != null) {
          this.info.nation = res.data.nation.text;
          this.info.nation_id = res.data.nation.value;
        }
        this.info.telphone = res.data.mobile;
        this.info.sex = res.data.sex.toString();
        if (res.data.avatar != "") {
          let url = "/" + res.data.avatar.split("/").slice(3).join("/");
          this.imgDataUrl = "https://gxrcexam.nccloudmedia.com" + url;
          this.info.avatar = url;
          console.log(111);
        }
      });
    },
    // 获取列表
    getList(k) {
      categoryLists({ key: k }).then((res) => {
        if (k == "nation") {
          this.nationList = res.data;
        }
        if (k == "marital_status") {
          this.maritalList = res.data;
        }
        if (k == "politics_status") {
          this.politicsList = res.data;
        }
        if (k == "education") {
          this.educationList = res.data;
        }
        if (k == "degree") {
          this.degreeList = res.data;
        }
      });
    },
    toUrl() {
      this.$router.push("/index");
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl, field) {
      // console.log('-------- crop success --------',imgDataUrl, field);
    },
    //上传成功回调
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      this.imgDataUrl = jsonData.data.url;
      this.info.avatar = jsonData.data.path;
      this.show = false;
    },
    //上传失败回调
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log("上传失败状态" + status);
      console.log("field: " + field);
    },
    handleAvatarSuccess(res, file) {
      if (file.response.code == 1) {
        let url = file.response.data.url;
        let path = file.response.data.path;
        this.imgDataUrl = url;
        this.imgDataPath = path;
        this.info.avatar = path;
        let width = file.response.data.width;
        let height = file.response.data.height;
        if (width > 305 || width < 285 || height > 423 || height < 403) {
          this.$message.error("请上传正确像素头像！");
          this.imgDataUrl = "";
          this.imgDataPath = "";
        }
        // if (width != 295 || height != 413) {
        //   this.$message.error("正确头像像素应为295px*413px！");
        //   this.imgDataUrl = "";
        //   this.imgDataPath = "";
        //   this.info.avatar = "";
        // }
      } else {
        this.$message.error("图片格式错误！请重新上传");
      }
    },
    handleAvatarSuccess2(res, file) {
      if (file.response.code == 1) {
        let url = file.response.data.url;
        let path = file.response.data.path;
        this.health_code_image = url;
        this.info.health_code_image = path;
      } else {
        this.$message.error("图片格式错误！请重新上传");
      }
    },
    handleAvatarSuccess3(res, file) {
      if (file.response.code == 1) {
        let url = file.response.data.url;
        let path = file.response.data.path;
        this.travel_code_image = url;
        this.info.travel_code_image = path;
      } else {
        this.$message.error("图片格式错误！请重新上传");
      }
    },
    handleAvatarSuccess4(res, file) {
      if (file.response.code == 1) {
        console.log(file);
        let arr = [];
        // let url = file.response.data.url;
        let path = file.response.data.path;
        // this.imgListUrl = this.imgListUrl.push(url);
        this.imgListPath.push(path);
        console.log(this.imgListPath);
      } else {
        this.$message.error("请重新上传");
      }
    },
    handleAvatarSuccess5(res, file) {
      if (file.response.code == 1) {
        let path = file.response.data.path;
        this.imgListPath5.push(path);
      } else {
        this.$message.error("请重新上传");
      }
    },
    handleAvatarSuccess6(res, file) {
      if (file.response.code == 1) {
        let path = file.response.data.path;
        this.imgListPath6.push(path);
      } else {
        this.$message.error("请重新上传");
      }
    },
    handleAvatarSuccess7(res, file) {
      if (file.response.code == 1) {
        let path = file.response.data.path;
        this.imgListPath7.push(path);
      } else {
        this.$message.error("请重新上传");
      }
    },
    handleAvatarSuccess8(res, file) {
      if (file.response.code == 1) {
        let path = file.response.data.path;
        this.imgListPath8.push(path);
      } else {
        this.$message.error("请重新上传");
      }
    },

    // 点击项
    handlePreview(file) {
      if (file.response) {
        window.open(file.response.data.url);
      } else {
        window.open(file.url);
      }
    },
    handlePreview5(file) {
      if (file.response) {
        window.open(file.response.data.url);
      } else {
        window.open(file.url);
      }
    },
    handlePreview6(file) {
      if (file.response) {
        window.open(file.response.data.url);
      } else {
        window.open(file.url);
      }
    },
    handlePreview7(file) {
      if (file.response) {
        window.open(file.response.data.url);
      } else {
        window.open(file.url);
      }
    },
    handlePreview8(file) {
      if (file.response) {
        window.open(file.response.data.url);
      } else {
        window.open(file.url);
      }
    },
    // 移除
    handleRemove(file, fileList) {
      if (file.response) {
        let str = file.response.data.path;
        let list = this.imgListPath;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath = list;
      } else {
        let str = "/" + file.url.split("/").slice(3).join("/");
        let list = this.imgListPath;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath = list;
      }
    },
    handleRemove5(file, fileList) {
      if (file.response) {
        let str = file.response.data.path;
        let list = this.imgListPath5;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath5 = list;
      } else {
        let str = "/" + file.url.split("/").slice(3).join("/");
        let list = this.imgListPath5;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath5 = list;
      }
    },
    handleRemove6(file, fileList) {
      if (file.response) {
        let str = file.response.data.path;
        let list = this.imgListPath6;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath6 = list;
      } else {
        let str = "/" + file.url.split("/").slice(3).join("/");
        let list = this.imgListPath6;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath6 = list;
      }
    },
    handleRemove7(file, fileList) {
      if (file.response) {
        let str = file.response.data.path;
        let list = this.imgListPath7;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath7 = list;
      } else {
        let str = "/" + file.url.split("/").slice(3).join("/");
        let list = this.imgListPath7;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath7 = list;
      }
    },
    handleRemove8(file, fileList) {
      if (file.response) {
        let str = file.response.data.path;
        let list = this.imgListPath8;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath8 = list;
      } else {
        let str = "/" + file.url.split("/").slice(3).join("/");
        let list = this.imgListPath8;
        list.forEach((item, index) => {
          if (str == item) {
            list.splice(index, 1);
          }
        });
        this.imgListPath8 = list;
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeRemove5(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeRemove6(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeRemove7(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeRemove8(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    beforeAvatarUpload2(file) {
      console.log(file);
      const str = file.type;
      const sta = str.indexOf("image") != -1;
      if (!sta) {
        this.$message.error("只能上传图片");
      }
      return sta;
    },
    beforeAvatarUpload3(file) {
      const str = file.type;
      const sta = str.indexOf("image") != -1;
      if (!sta) {
        this.$message.error("只能上传图片");
      }
      return sta;
    },
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.info.native_place_code.length; i++) {
        loc += CodeToText[this.info.native_place_code[i]] + "/";
      }
      this.info.native_place = loc.substring(0, loc.lastIndexOf("/"));
      console.log(this.info.native_place);
      console.log(this.info.native_place_code);
      return loc;
    },
    handleChange2() {
      var loc = "";
      for (let i = 0; i < this.info.registered_residence_code.length; i++) {
        loc += CodeToText[this.info.registered_residence_code[i]] + "/";
      }
      this.info.registered_residence = loc.substring(0, loc.lastIndexOf("/"));
      console.log(this.info.registered_residence);
      console.log(this.info.registered_residence_code);
      return loc;
    },
    change(e) {
      this.info.nation_id = e;
    },
    change2(e) {
      this.info.marital_status_id = e;
    },
    change3(e) {
      this.info.politics_status_id = e;
    },
    change4(e) {
      this.info.education_id = e;
    },
    change5(e) {
      this.info.degree_id = e;
    },
    toStep() {
      this.step = 2;
      this.backTop();
    },
    //返回顶部
    backTop() {
      const that = this;
      //使用定时器设置滑动过渡效果
      document.documentElement.scrollTop = 0;
    },
    // 提交
    toSubmit() {
      console.log(this.info);
      let info = this.info;
      let that = this;
      let id = this.$route.query.id;
      info.exam_id = id;
      info.id_type = 1;
      let time1 = info.birthday; //生日
      let party_time = info.party_time;
      info.diploma_files = this.imgListPath.toString();
      info.electronic_record_files = this.imgListPath5.toString();
      info.certificate_files = this.imgListPath6.toString();
      info.degree_files = this.imgListPath7.toString();
      info.idcard_files = this.imgListPath8.toString();
      if (typeof time1 === "object") {
        info.birthday = getFormat(info.birthday);
      }
      if (typeof party_time === "object") {
        info.party_time = getFormat(info.party_time);
      }
      let education = info.education;
      let experience = info.experience;
      let certificate = info.certificate;
      if (education.length == 0) {
      } else {
        education.forEach((item) => {
          if (typeof item.start_time === "object") {
            item.start_time = getMouth(item.start_time);
            item.end_time = getMouth(item.end_time);
          }
          item.is_fulltime = item.is_fulltime.toString();
        });
      }
      if (experience.length == 0) {
      } else {
        experience.forEach((item) => {
          if (typeof item.start_time === "object") {
            item.start_time = getMouth(item.start_time);
            item.end_time = getMouth(item.end_time);
          }
        });
      }
      if (certificate.length == 0) {
      } else {
        certificate.forEach((item) => {
          if (typeof item.get_time === "object") {
            item.get_time = getFormat(item.get_time);
          }
        });
      }
      if (!isCardNo(this.info.id_number)) {
        this.$message.error("请输入正确的身份证号");
        return false;
      }
      if (!isPhone(this.info.telphone)) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      let str =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (!str.test(this.info.email)) {
        this.$message.error("请输入正确的邮箱");
        return false;
      }
      console.log(info);
      this.$confirm("确定要提交吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (that.type == 1) {
            applySubmit(info).then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: "提交成功!",
                });
                that.$router.go(-1);
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            applyEdit(info).then((res) => {
              if (res.code == 1) {
                this.$message({
                  type: "success",
                  message: "提交成功!",
                });
                that.$router.go(-1);
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        })
        .catch(() => {});
    },
    toAdd(n) {
      console.log(this.info.education);
      if (n == 1) {
        this.info.education.push({
          time: "",
          school_name: "",
          major: "",
          is_fulltime: "",
          start_time: "",
          end_time: "",
          education_id: "",
          degree_id: "",
          type: "1",
        });
        console.log(this.info.education);
      }
      if (n == 2) {
        this.info.certificate.push({
          name: "",
          get_time: "",
          organization_name: "",
          serivce_direction: "",
        });
      }
      if (n == 3) {
        this.info.experience.push({
          start_time: "",
          end_time: "",
          time: "",
          name: "",
          position: "",
        });
      }
    },
    todel(n, i) {
      if (n == 1) {
        let arr = this.info.education;
        arr.splice(i, 1);
      }
      if (n == 2) {
        let arr = this.info.certificate;
        arr.splice(i, 1);
      }
      if (n == 3) {
        let arr = this.info.experience;
        arr.splice(i, 1);
      }
    },
    toGo() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f2f5f8;
  z-index: -1;
}
.top-img {
  margin: 0 auto;
  padding: 20px 20%;
  background: white;
  img {
    width: 55%;
  }
  div {
    color: #0089e1;
    font-size: 16px;
  }
}
.apply-nav {
  width: 60%;
  margin: 20px auto;
  .nav-item {
    background: white;
    color: #636c71;
    font-size: 22px;
    padding: 13px 30px;
    border-radius: 5px;
    margin-right: 10px;
    width: 130px;
  }
  .nav-item-a {
    color: #0089e1;
  }
  .nav-item:nth-child(3) {
    justify-content: center;
  }
}
.info-type1 {
  width: 60%;
  margin: 20px auto;
  padding-bottom: 40px;
  background: white;
  .type2-tit {
    color: #0089e1;
    font-size: 18px;
    padding: 12px 44px;
    border-bottom: solid 1px #e6e6e6;
    align-items: center;
    span {
      color: #686868;
      font-size: 12px;
      margin-left: 5px;
    }
    img {
      width: 24px;
      margin-right: 10px;
    }
  }
  .type2-tit2 {
    color: white;
    font-size: 15px;
    padding: 10px 10px;
    cursor: pointer;
    text-align: center;
    background: #4ca8e2;
    width: 15%;
    justify-content: center;
    text-align: center;
    margin: 20px auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    i {
      font-size: 25px;
      margin-right: 10px;
    }
  }
  .type2-mian {
    width: 70%;
    margin: 0 auto;
    .main-left {
      width: 29%;
      text-align: center;
      margin-top: 20px;
      .up {
        width: 80px;
        height: 80px;
        position: relative;
        top: 50%;
        margin-top: -40px;
        border: dashed 1px #dedede;
      }
      .img-tips {
        width: 126px;
        margin: 10px auto;
        font-size: 14px;
      }
    }
    .main-right {
      width: 60%;
      div:nth-child(1) {
        font-size: 18px;
        line-height: 3;
      }
      div:nth-child(2) {
        font-size: 14px;
        color: #817f7f;
        a,
        span {
          color: #0089e1;
        }
      }
    }
  }
  .input-items {
    width: 80%;
    margin-left: 10%;
    position: relative;
    .del {
      position: absolute;
      font-size: 30px;
      right: 20px;
      color: #0089e1;
    }
    .input-item {
      align-items: center;
      font-size: 18px;
      margin: 20px 0;
      .input-tit {
        width: 30%;
        text-align: right;
        margin-right: 30px;
        span {
          color: #ff2727;
        }
      }
    }
  }
  .input-items2 {
    width: 85%;
    margin: 20px auto;
    background: #fbfbfb;
    border-radius: 10px;
    padding: 20px;
  }
  .input-item {
    width: 50%;
    // padding: 20px 0;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 110px;
  height: 140px;
  line-height: 140px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: auto;
  margin: 0 auto;
  box-shadow: 0 3px 3px #e1d9d9;
  border: dashed 1px #707070;
  display: block;
}
.status1 {
  padding: 20px 0 40px;
  border-radius: 5px;
  div {
    width: 95%;
    margin: 0 auto;
    font-size: 20px;
  }
}
.status2 {
  padding: 40px 0;
  border-radius: 10px;
  .status2-main {
    margin: 0 40px;
    font-size: 20px;
    .code-img {
      margin-top: 20px;
      color: #0089e1;
      font-size: 22px;
      span {
        color: #f31616;
      }
      .code-img1 {
        img {
          width: 228px;
        }
      }
    }
  }
  .upImg {
    padding: 20px;
    text-align: center;
    align-items: center;
  }
}
.checkbox {
  text-align: center;
  font-size: 20px;
}

.sub-btn2 {
  background: #889399;
  font-size: 14px;
  width: 100px;
  line-height: 40px;
  margin: 40px auto 30px;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.sub-btn {
  background: #1baefd;
  font-size: 14px;
  width: 100px;
  line-height: 40px;
  margin: 40px auto 30px;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.pickdata {
  width: 70%;
  span {
    margin: 0 10px;
  }
}
.fujian {
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  .fujian-item {
    border-radius: 10px;
    font-size: 20px;
    padding: 20px;
    width: 43%;
    background: #f6f6f6;
    margin-top: 20px;
    color: #000000;
    min-height: 110px;
  }
  .upFujian {
    img {
      width: 22px;
      height: 22px;
      margin-left: 20px;
    }
    span {
      color: #0089e1;
      font-size: 16px;
    }
  }
}
</style>